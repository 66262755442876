.request__container {
    box-sizing: border-box;
    width: 100%;
}

.requestlist {
    margin: 0 40px;
    padding: 20px;
    background-color: white;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-y: scroll;
    height: calc(100vh - 120px);
}

.requestlist__header_caption {
    font-size: 12px;
    line-height: 16px;
    color: #879FB9;
}

.requestlist__captions {
    color: #313A43;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    height: 24px;
    padding-bottom: 10px;
}

.requestlist__items {
    display: grid;
    grid-template-columns: 6rem 9rem 9rem 9rem 9rem 10rem;
    gap: 10px;
    justify-content: space-between;
    padding: 5px;
    width: 100%;
}

.requestlist__hover:hover {
    border: none;
    background-color: #B6CADF;
    border-radius: 5px;
}

.requestlist__items_caption {
    color: #313A43;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    align-self: center;
}

.requestlist__items_sum {
    text-align: left;
}

.requestlist__items_status {
    line-height: 20px;
    padding: 5px;
    background-color: #EBF4FD;
    color: #879FB9;
    border-radius: 38px;
    border: none;
    text-align: center;
}


.requestlist__items_status-payd {
    background-color: #E7F5F1;
    color: #219653;
}

.requestlist__items_status-approved {
    background-color: #FFECDD;
    color: #FF7800;
}

.requestlist__items_status-cancel {
    background-color: #FFF2F5;
    color: #EB5757;
}

@media screen and (max-width: 768px) {
    .requestlist {
        margin: 0px 24px;
    }

    .requestlist__items {
        display: grid;
        grid-template-columns: 113px 110px 150px 80px 140px;
        gap: 16px;
        padding-right: 32px;
    }

    .requestlist__header_caption-none {
        display: none;
    }

    .requestlist__items_caption-none {
        display: none;
    }
}

@media screen and (max-width: 425px) {
    .requestlist {
        padding: 20px;
        margin: 0px 40px;
        background: none;
        border-radius: 20px;
    }

    .requestlist__header_caption {
        display: none;
    }

    .requestlist__items {
        display: flex;
        gap: 0px;
        flex-direction: column;
        margin-bottom: 10px;
        background-color: white;
        border-radius: 15px;

    }

    .requestlist__items_caption:nth-child(1) {
        display: none;
    }

    .requestlist__items_caption:nth-child(2) {
        padding: 0;
        margin: 10px;
        order: 1;
    }

    .requestlist__items_caption:nth-child(3) {
        order: 2;
    }

    .requestlist__items_caption:nth-child(4) {
        order: 3;
    }
}