.newregistry {
    margin: 0 40px;
}

.newregistryInfo {
    margin: 0;
    padding: 32px 32px 36px 32px;
    height: 112px;
    background-color: #FFFFFF;
    border-radius: 20px;
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-template-rows: 10px 10px;
    gap: 12px;
}

.newregistryInfoCaption {
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    color: #879FB9;
    margin: 0 0 12px 0;
}

.newregistryInfoValue {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: #313A43;
}

.newregistryList {
    display: grid;
    grid-template-columns: 6rem 6rem 6rem 6rem 6rem 6rem 6rem;
    gap: 10px;
    justify-content: space-between;
    padding: 5px;
    width: 100%;
}

.newregistrylist__item {
    padding: 0;
    font-size: 14px;
    line-height: 16px;
    color: #313A43;
}

.newregistryBtn {
    background-color: #FFFFFF;
    border: none;
    margin: 30px 20px 0 0;
    padding: 0 24px;
    height: 48px;
}