.navbar__list {
    display: flex;
    flex-direction: row;
    list-style: none;
    margin-bottom: 20px;
    margin-left: 40px;
}

.navbar__status {
    margin: 0px 16px;
    font-size: 14px;
    line-height: 16px;
    color: #879FB9;
}

.navbar__status:hover {
    color:  #0078FF;
    cursor: pointer;
}


.navbar__status_active {
    color:  #0078FF;
    text-decoration: underline;
}

.navbar__select {
    display: none;
}

@media screen and (max-width: 768px) {
    .navbar__list {
        display: none;
    }

    .navbar__select {
        display: flex;
        margin-left: 30px;
        margin-bottom: 15px;
        width: 220px;
        height: 40px;
    }
}