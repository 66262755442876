.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-right: 76 px;
    padding-left: 40px;
}

.header__user {
    display: flex;
    align-items: center;
    margin-right: 76px;
}

.header__user_logo {
    height: 48px;
    width: 48px;
    background-color: red;
    border-radius: 30px;
    margin-right: 12px;
}

.header__user_company {
    background-color: #F5F7FA;
    border: none;
    color: #313A43;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
}

.header__user_role {
    color: #879FB9;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
}

.header__createRequestBtn {
    background-color: #0078FF;
    border-radius: 8px;
    width: 204px;
    height: 48px;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
}

.header__createRequestBtn:hover {
    background-color: antiquewhite;
    color: black;
}

.header__burger {
    display: none;
}

@media screen and (max-width: 768px) {
    .header__burger {
        display: flex;
        width: 48px;
        height: 48px;
        background-image: url('../../images/menu_burger.svg');
        border: none;
    }

    .header__user {
        margin-right: 40px;
    }
}