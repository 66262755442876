.app {
    margin: 0 auto;
    max-width: 1440px;
    font-family: Montserrat;
    display: flex;
    flex-direction: row;
    height: 100vh;
}

.page {
    width: auto;
    display: flex;
    flex-direction: column;
    background-color: #F5F7FA;
    padding: 0;
    width: 100%;
}

@media screen and (max-width: 768px) {
    .page {
        max-width: 760px;
        padding: 0;
        margin: 0 auto;
    }
}