.organization {
    margin: 0 40px;
}

.organization__headerContainer {
    display: grid;
    grid-template-columns: 150px 250px;
}

.organization__trackContainer {
    display: grid;
    grid-template-columns: 250px 300px;
    gap: 32px;
    margin: 0;
    padding: 40px 0 0 0;
}

.organization__input {
    height: 25px;
}

.organization__button {
    margin-top: 32px;
    margin-right: 32px;
    background-color: #0078FF;
    width: 278px;
    height: 48px;
    color: #FFFFFF;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    border-radius: 20px;
    text-decoration: none;
    text-align: center;
    border-radius: 8px;
    border: none;
}