.registrylist {
    margin: 0 40px;
    padding: 20px;
    background-color: white;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-y: scroll;
    height: calc(100vh - 120px);
}

.registrylist__header_caption {
    font-size: 12px;
    line-height: 16px;
    color: #879FB9;

}

.registrylist__captions {
    display: flex;
    flex-direction: row;
    color: #313A43;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    height: 24px;
    padding-left: 25px;
}


.registrylist__items {
        display: grid;
        grid-template-columns: 6rem 6rem 6rem 9rem 10rem;
        gap: 10px;
        justify-content: space-between;
        padding: 5px;
        width: 100%;
}

.registrylist__itemCaption {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: #313A43;
    padding-left: 20px;
}