.newOrganization {
    margin: 0 40px;
}

.newOrganization__addBtn {
    border-radius: 8px;
    background-color: #FFFFFF;
    border: none;
    margin: 30px 20px 0 0;
    padding: 0 24px;
    height: 48px;
}