.request {
    margin: 0px 40px;
}

.request__form {
    background-color: #FFFFFF;
    margin: 0 0 32px 0;
    padding: 32px 40px;
    border-radius: 20px;
    display: grid;
    grid-template-columns: 100px 100px 100px 100px;
    grid-template-rows: 32px 32px 32px 32px;
    gap: 10px;

}

.request__form_field {
    max-width: 385px;
    background-color: F5F7FA;
    align-content: center;
    padding-right: 10px;
    height: 32px;
    border: #B6CADF 1px solid;
}

.request__form_field_input {
    background-color: #F5F7FA;
}

.request__form_caption {
    align-self: center;
    margin: 0px 20px;
}

.request__form_fieldSum {
    border: 1px;
    border-color: #B6CADF;
    border-radius: 4px;
}

.requestBtn {
    border-radius: 8px;
    background-color: #FFFFFF;
    border: none;
    margin: 0 20px 0 0;
    padding: 0 24px;
    height: 48px;
}

.requestBtn:hover {
    background-color: #B6CADF;
    color: #EBF4FD;
}

.request_form_radioContainer {
    display: flex;
    justify-items: center;
    margin: 0;
    padding: 0;
    gap: 10px;
}

.request__form_radioLabel {
    align-self: center;
}

@media screen and (max-width: 768px) {

    .request {}

    .request__form {
        margin: 0 15px;
        grid-template-columns: auto auto;
        grid-template-rows: repeat(8, 32px);
    }

    .request__form_caption {
        align-self: center;
        margin: 0;
        padding-left: 20px;
    }
}