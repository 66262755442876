.request {
    height: 100%vh;
}

.request__form {
    background-color: #FFFFFF;
    margin: 0 40px 32px 0;
    padding: 32px 0;
    border-radius: 20px;
    display: grid;
    grid-template-columns: 190px 250px 190px 350px;
    grid-template-rows: 32px 32px 32px 32px;
    gap: 10px;

}

.request__form_field {
    max-width: 385px;
    background-color: F5F7FA;
    align-self: center;
    padding: 0px 5px;
    border-radius: 4px;

}

.request__form_caption {
    align-self: center;
    margin: 0px 20px;
}

.request__form_fieldSum {
    border: 1px;
    border-color: #B6CADF;
    border-radius: 4px;
}

.requestBtn {
    border-radius: 8px;
    background-color: #FFFFFF;
    border: none;
    margin: 0 20px 0 0;
    padding: 0 24px;
    height: 48px;
}

.requestBtn:hover {
    background-color: #B6CADF;
    color: #EBF4FD;
}

.request_form_radioContainer {
    margin: 0;
    padding: 0;
    align-items: end;
    justify-items: end;
    align-self: center;
}

.requestBtn_cancel {
    background-color: red;
    color: #FFFFFF;
    border-radius: 8px;
    border: none;
    margin: 0 20px 0 0;
    padding: 0 24px;
    height: 48px;
}

.request__logCaptions {
    display: grid;
    grid-template-columns: 100px 100px 170px 210px 400px;
    font-size: 12px;
}

.request__logFields {
    font-size: 12px;
}

.requestBtnContainer {
    margin: 20px 0;
}

.request__commentInput {
    width: 500px;
    background-color: F5F7FA;
    align-self: center;
    padding: 16px;
    height: 48px;
    border: #B6CADF 1px solid;
    resize: none;
    border-radius: 6px;
}

.request__submitContainer-none {
    display: none;
}

@media screen and (max-width: 768px) {

    .request {}

    .request__form {
        margin: 0 15px;
        grid-template-columns: auto auto;
        grid-template-rows: repeat(8, 32px);
    }

    .request__form_caption {
        align-self: center;
        margin: 0;
        padding-left: 20px;
    }
}