.adminPageOrganization__addBtn {
        margin-top: 32px;
        background-color: #0078FF;
        width: 278px;
        height: 48px;
        color: #FFFFFF;
        font-size: 16px;
        line-height: 20px;
        font-weight: 500;
        border-radius: 20px;
        text-decoration: none;
        text-align: center;
        border-radius: 8px;
        border: none;
}