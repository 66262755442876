.sidebar {
    width: 240px;
    display: flex;
    flex-direction: column;
    color: #FFFFFF;
    padding-left: 40px;
    padding-right: 40px;
    position: relative;
    transition: 0.8s;
    height: 100vh;
    padding: 20px 40px;
}

.sidebar__small {
    width: 92px;
    padding: 20px;
    align-items: center;
}

.sidebarHideBtn {
    width: 24px;
    height: 48px;
    background-image: url("../../images/chevron.png");
    border: none;
    position: absolute;
    right: 0;
    transition: 0.8s;
}

.sidebarHideBtn-close {
    right: -25px;
    transform: scale(-1);
}

.sidebarHideBtn:hover {
    opacity: 60%;
    cursor: pointer;
}

.sidebarCloseBtn {
    display: none;
}

.sidebar__logo {
    display: flex;
    align-items: center;
    text-decoration: none;
}

.sidebar__logo_image {
    width: 32px;
    height: 32px;
}

.sidebar__logo_caption {
    margin-left: 10px;
    color: #313A43;
    font-size: 20px;
    line-height: 16px;
    font-weight: 700;
}

.sidebarMenu {
    display: flex;
    flex-direction: column;
    margin-top: 80px;
    margin-bottom: 40px;
    color: #879FB9;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
}

.sidebarMenuCaption {
    margin-bottom: 24px;
}

.sidebarBtn {
    padding: 0;
    margin: 0;
    /* width: 200px; */
    text-wrap: nowrap;
    height: 24px;
    display: flex;
    margin-bottom: 20px;
    background: none;
    border: none;
    cursor: pointer;
    text-decoration: none;
    text-transform: none;
    font-style: normal;
}

.sidebarBtn__signout {}

.sidebarBtn:hover {
    background-color: #B6CADF;
    border-radius: 20px;
}

.sidebarBtnLogo {
    margin-right: 2px;
    width: 24px;
    height: 24px;
}

.sidebarBtnCaption {
    text-align: left;
    font-size: 16px;
    line-height: 20px;
    color: #313A43;
}

.sidebarBtnCounter {
    margin: 0;
    padding: 0;
    background-color: #EB5757;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    font-size: 12px;
    line-height: 14px;
    color: #FFFFFF;
    text-align: center;
}

.sidebarMenuCaption {
    font-family: Montserrat;
    margin-bottom: 24px;

}

.sidebarExitBtn {
    text-align: left;
    color: #879FB9
}

.sidebar__overlay {
    width: auto;
}


@media screen and (max-width: 768px) {

    .sidebar__overlay {
        background-color: #313a4371;
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
    }

    .sidebar {
        display: flex;
        padding-left: 40px;
        padding-right: 40px;
        position: absolute;
        right: 0;
        background-color: #FFFFFF;
        transition: 0s;
    }

    .sidebar__close {
        visibility: hidden;
        transition: 0s;
    }

    .sidebarHideBtn-close {
        display: none;
    }

    .sidebar__logo {
        display: none;
    }

    .sidebarCloseBtn {
        display: flex;
        width: 48px;
        height: 48px;
        border: none;
        justify-content: center;
        align-items: center;
    }

    .sidebarMenu {
        margin-top: 55px;
    }
}