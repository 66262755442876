.organizationsList {
    margin: 0px 40px;
}

.organizationsList__container {
    background-color: white;
    border-radius: 20px;
    padding: 20px 20px;
}

.organizationsList__captions {
    color: #313A43;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    height: 24px;
    padding-bottom: 10px;
}

.organizationsList__values {
    display: grid;
    grid-template-columns: 300px 150px 150px;
    color: #313A43;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    padding: 10px;
}

.organizationsList__hover:hover {
    border: #879FB9 1px solid;
    background-color: #B6CADF;
    border-radius: 5px;
}

.organizationsList__addBtn {
    margin-top: 32px;
    background-color: #0078FF;
    width: 278px;
    height: 48px;
    color: #FFFFFF;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    border-radius: 20px;
    text-decoration: none;
    text-align: center;
    border-radius: 8px;
    border: none;
}