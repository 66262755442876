.users {
    margin: 0 40px;
}

.users__container {
    margin: 0;
    background-color: #FFFFFF;
    border-radius: 20px;
    padding: 20px 20px;
}

.users__captions {
    color: #879FB9;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
}

.users__values {
    display: grid;
    grid-template-columns: 250px 250px 250px 240px;
    color: #313A43;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
}

.users__addBtn {
    margin-top: 32px;
    background-color: #0078FF;
    width: 278px;
    height: 48px;
    color: #FFFFFF;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    border-radius: 20px;
    text-decoration: none;
    text-align: center;
    border-radius: 8px;
    border: none;
}

.users__addBtn:hover {
    background-color: #B6CADF;
    color: #EBF4FD;
}

.users__select {
    height: 32px;
}

@media screen and (max-width: 768px) {
    .users__values {
        display: grid;
        grid-template-columns: 160px 150px 180px 140px;
        color: #313A43;
        font-size: 14px;
        line-height: 16px;
        font-weight: 400;
    }
}