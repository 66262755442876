.registry__container {
    margin: 0 40px;
    padding: 20px;
    background-color: white;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-y: scroll;
}

.registryInfo {
    margin: 0;
    padding: 32px 32px 36px 32px;
    height: 92px;
    background-color: #FFFFFF;
    border-radius: 20px;
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-template-rows: 10px 10px;
    gap: 12px;
}

.registryInfoCaption {
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    color: #879FB9;
    margin: 0 0 12px 0;
}

.registryInfoValue {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: #313A43;
}

.registryList {
    display: grid;
    grid-template-columns: 6rem 6rem 6rem 9rem 9rem;
    gap: 10px;
    justify-content: space-between;
    padding: 5px;
    width: 100%;
}

.registrylist__item {
    font-size: 14px;
    line-height: 16px;
    color: #313A43;
    
}

.registryBtn {
    background-color: #FFFFFF;
    border: none;
    margin: 30px 20px 0 0;
    padding: 0 24px;
    height: 48px;
}